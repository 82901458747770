@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~@syncfusion/ej2-base/styles/fabric.css';
@import '~@syncfusion/ej2-buttons/styles/fabric.css';
@import '~@syncfusion/ej2-calendars/styles/fabric.css';
@import '~@syncfusion/ej2-dropdowns/styles/fabric.css';
@import '~@syncfusion/ej2-inputs/styles/fabric.css';
@import '~@syncfusion/ej2-lists/styles/fabric.css';
@import '~@syncfusion/ej2-layouts/styles/fabric.css';
@import '~@syncfusion/ej2-navigations/styles/fabric.css';
@import '~@syncfusion/ej2-popups/styles/fabric.css';
@import '~@syncfusion/ej2-splitbuttons/styles/fabric.css';
@import '~@syncfusion/ej2-grids/styles/fabric.css';
@import '~@syncfusion/ej2-treegrid/styles/fabric.css';
@import '~@syncfusion/ej2-react-gantt/styles/fabric.css';
@import '~@syncfusion/ej2-pdfviewer/styles/fabric.css';
@import '~@syncfusion/ej2-richtexteditor/styles/fabric.css';

@import '~@syncfusion/ej2-base/styles/fabric-dark.css';
@import '~@syncfusion/ej2-buttons/styles/fabric-dark.css';
@import '~@syncfusion/ej2-calendars/styles/fabric-dark.css';
@import '~@syncfusion/ej2-dropdowns/styles/fabric-dark.css';
@import '~@syncfusion/ej2-inputs/styles/fabric-dark.css';
@import '~@syncfusion/ej2-lists/styles/fabric-dark.css';
@import '~@syncfusion/ej2-layouts/styles/fabric-dark.css';
@import '~@syncfusion/ej2-navigations/styles/fabric-dark.css';
@import '~@syncfusion/ej2-popups/styles/fabric-dark.css';
@import '~@syncfusion/ej2-splitbuttons/styles/fabric-dark.css';
@import '~@syncfusion/ej2-grids/styles/fabric-dark.css';
@import '~@syncfusion/ej2-treegrid/styles/fabric-dark.css';
@import '~@syncfusion/ej2-react-gantt/styles/fabric-dark.css';
@import '~@syncfusion/ej2-pdfviewer/styles/fabric-dark.css';
@import '~@syncfusion/ej2-richtexteditor/styles/fabric-dark.css';

.e-custome-holiday {
  z-index: 25;
}

.e-gantt-child-critical-taskbar-inner-div {
  background: #c20e4d !important;
  outline: 1px solid #c20e4d !important;
}

.e-calendar {
  background-color: inherit;
  border: none;
  width: 100%;
}

.e-calendar .e-header .e-title,
.e-bigger.e-small .e-calendar .e-header .e-title {
  color: inherit;
  font-size: 20px;
  font-weight: bold;
}

.e-calendar .e-content.e-year td > span.e-day,
.e-calendar .e-content.e-decade td > span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td > span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td > span.e-day {
  @apply rounded-xl bg-content4 text-black hover:bg-content2 dark:text-white;
}

.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  @apply bg-[#38a9ff];
}
